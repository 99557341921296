import React, { useEffect, useState } from 'react';
import { Select, InputLabel, TextField, Button, Checkbox, OutlinedInput, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, InputAdornment } from '@mui/material';
import { Box, Card, Grid, FormControl, MenuItem, FormControlLabel } from '@mui/material';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import CommonAuth from '../../../../state/APIConnect/Common';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';
import { AppSettings } from '../../../../AppSettings';
import GoogleAddressAutoComplete from '../../../../components/AutoCompleteAddress/GoogleAddressAutoComplete';
import { Search } from '@mui/icons-material';

const AddInstitutionPage = () => {

    const [organizationTypeId, setOrganizationTypeId] = useState(0);
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [corePurposeName, setCorePurposeName] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [provinceId, setProvinceId] = useState(0);
    const [districtMunicipalityId, setDistrictMunicipalityId] = useState(0);
    const [localsByDistrictMunicipalityId, setLocalsByDistrictMunicipalityId] = useState(0);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [mainOfficeaddress, setMainOfficeAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [alternativeNumber, setAlternativeNumber] = useState('');
    const [email, setEmail] = useState('');
    const [alternativeEmail, setAlternativeEmail] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [website, setWebsite] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
    const navigate = useNavigate();

    const [institutionSearchResult, setInstitutionSearchResult] = useState('')

    const [openDialog, setOpenDialog] = useState(false);

    const [searchRegistration, setSearchRegistration] = useState('');

    const [organizationTypes, setOrganizationTypes] = useState([]);
    const [categories, setCategories] = useState([])
    const [provinces, setProvinces] = useState([])
    const [corePurposes, setCorePurposes] = useState([])
    const [districtMunicipalities, setDistrictMunicipalities] = useState([])
    const [localsByDistrictMunicipalities, setLocalsByDistrictMunicipalities] = useState([])

    const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setCorePurposeName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleBack = () => {
        navigate("/signUp");
    }

    const GetAllOrganizationType = async () => {
        const res = await NSPMonitorAuth.GetAllOrganizationType();
        setOrganizationTypes(res.content)
    }

    const GetAllCategories = async () => {
        const res = await NSPMonitorAuth.GetAllCategories(null, pagination.pageIndex, pagination.pageSize);
        setCategories(res.content.items);
    }

    const GetAllProvinces = async () => {
        const res = await CommonAuth.GetAllProvinces();
        setProvinces(res.content);
    }

    const GetAllPillars = async () => {
        const res = await NSPMonitorAuth.GetAllPillars(AppSettings.Platform.NSPMonitor);
        setCorePurposes(res.content)
    }

    const GetLocalsByDistrictMunicipalityId = async (event) => {
        let distId = event.target.value;
        console.log("🚀 ~ GetLocalsByDistrictMunicipalityId ~ distId:", distId)
        setDistrictMunicipalityId(distId)
        const res = await CommonAuth.GetLocalsByDistrictMunicipalityId(distId, null, pagination.pageIndex, pagination.pageSize);
        console.log("🚀 ~ GetLocalsByDistrictMunicipalityId ~ res:", res)
        setLocalsByDistrictMunicipalities(res.content.items);
    }

    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles(Array.from(files));
    };

    useEffect(() => {
        GetAllOrganizationType();
        GetAllPillars();
        GetAllCategories();
        GetAllProvinces();



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        debugger;
        try {
            const formData = new FormData();
            formData.append('id', 0);
            formData.append('organizationTypeId', organizationTypeId);
            formData.append('registrationNumber', registrationNumber);
            formData.append('name', name);
            formData.append('description', description);
            formData.append('categoryId', categoryId);
            formData.append('provinceId', provinceId);
            formData.append('CorePurposeName', corePurposeName);
            formData.append('districtMunicipalityId', districtMunicipalityId);
            formData.append('localId', localsByDistrictMunicipalityId);
            formData.append('phoneNumber', phoneNumber);
            formData.append('alternativeNumber', alternativeNumber);
            formData.append('emailAddress', email);
            formData.append('alternativeEmailAddress', alternativeEmail);
            formData.append('address', mainOfficeaddress);
            formData.append('website', website);
            formData.append('formFiles', selectedFiles);
            for (const file of selectedFiles) {
                formData.append('formFiles', file);
            }

            const resp = await NSPMonitorAuth.RegisterInstitution(formData);
            if (resp.content !== null) {
                toast.success(resp.content.message, { position: "top-right", });
                setOrganizationTypes([]);
                setCorePurposeName([])
                setOrganizationTypes([])
                setName('');
                setDescription('');
                setOrganizationTypeId(0);
                setRegistrationNumber('');
                setCategoryId(0);
                setProvinceId(0);
                setDistrictMunicipalityId(0);
                setLocalsByDistrictMunicipalityId(0);
                setPhoneNumber('');
                setAlternativeNumber('')
                setEmail('');
                setAlternativeEmail('');
                setMainOfficeAddress('');
                setWebsite('');
                setSelectedFiles([])
                setOpenDialog(true);
            }
            else {
                toast.error(resp.error_content.message, { position: "top-right", })
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error("Error saving occur while saving data", { position: "top-right", })
        }
    }

    const enableSubmitButton = () => {
        return (
            corePurposeName.length > 0 &&
            categoryId !== 0 &&
            provinceId !== 0 &&
            districtMunicipalityId !== 0 &&
            // localsByDistrictMunicipalityId !== 0 &&
            name.length > 0 &&
            mainOfficeaddress.length > 0 &&
            email.length > 0
        );
    }

    const GetDistrictMunicipalityByProvinceId = async (provinceId) => {
        // Check if provinceId is coming from an event or directly
        const proId = provinceId?.target ? provinceId.target.value : provinceId;
        setProvinceId(proId);
        const res = await CommonAuth.GetDistrictMunicipalityByProvinceId(proId, null, pagination.pageIndex, pagination.pageSize);
        setDistrictMunicipalities(res.content.items);
    }


    const handleSearch = async (event) => {
        event.preventDefault();
        const res = await NSPMonitorAuth.FindInstitutionByRegistrationNumber(searchRegistration);
        let data = res.content;
        console.log("🚀 ~ handleSearch ~ data:", data)
        if (!data) {
            setInstitutionSearchResult(`No institution found with registration number ${searchRegistration}`);
            setOpenForgetPasswordDialog(true);
            return;
        }
        if (data.isPendingApproval) {
            setInstitutionSearchResult(`Please be patient. The registration for your institution, ${data.name}, 
                is under review by the system administrator. Once the review is complete, you will receive an email. Thank you.`);
            setOpenForgetPasswordDialog(true);
            return;
        }

        const oneHourAgo = Date.now() - 60 * 60 * 1000;
        // const fourDaysAgo = Date.now() - 4 * 24 * 60 * 60 * 1000;
        if (data.isDeclined && new Date(data.declinedDate) > oneHourAgo) {
            setInstitutionSearchResult(`The review of the ${data.name} institution is complete. Please check the email sent to ${data.email} for the results.`);
            setOpenForgetPasswordDialog(true);
            return;
        }

        if (data.isApproved) {
            setInstitutionSearchResult(`We are delighted to inform you that the registration of ${data.name} has been approved. People can now register under ${data.name}`)
            setOpenForgetPasswordDialog(true);
            return;
        }
        else {
            const corePurposesArray = data.corePurpose.split(',').map(role => role.trim());
            GetDistrictMunicipalityByProvinceId(data.provinceId);
            setOrganizationTypeId(data.fK_OrganizationTypeId);
            setRegistrationNumber(data.registrationNumber);
            setCorePurposeName(corePurposesArray || []);
            setCategoryId(data.categoryId);
            setProvinceId(data.provinceId);
            setDistrictMunicipalityId(data.districtMunicipalityId);
            setLocalsByDistrictMunicipalityId(data.localId);
            setName(data.name);
            setDescription(data.description);
            setMainOfficeAddress(data.address);
            setPhoneNumber(data.phoneNumber);
            setAlternativeNumber(data.alternativeNumber);
            setEmail(data.email);
            setAlternativeEmail(data.alternativeEmail);
            setWebsite(data.websiteUrl);
        }
    };


    return (
        <Card sx={{ minWidth: 275 }}>
            <Box m="0.5rem 2.5rem">
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <h1>Register Institution</h1>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <FormControl sx={{ m: 1, width: '35ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-search">Search registration number</InputLabel>
                                <OutlinedInput type="text" value={searchRegistration} onChange={(e) => { setSearchRegistration(e.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="search registration number" onClick={handleSearch} edge="end" >
                                                <Search />  {/* Material-UI Search Icon */}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Search registration number" />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Organization Types *</InputLabel>
                                <Select defaultValue={0} label="Organization Types *" value={organizationTypeId} onChange={(e) => { setOrganizationTypeId(e.target.value) }}>
                                    <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                                    {organizationTypes ? organizationTypes.map(organizationType => {
                                        return (<MenuItem key={organizationType.id} value={organizationType.id}> {organizationType.name} </MenuItem>)
                                    }) : <MenuItem key={0} value={0}>{'No Category'} </MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <TextField fullWidth label="Registration Number" value={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel>Core Purpose</InputLabel>
                                <Select
                                    multiple
                                    value={corePurposeName}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Core Purpose" />}
                                    renderValue={(selected) => selected.join(', ')}>
                                    {corePurposes.map((corePurpose) => (
                                        <MenuItem key={corePurpose.id} value={corePurpose.description}>
                                            <FormControlLabel
                                                control={<Checkbox checked={corePurposeName.indexOf(corePurpose.description) > -1} />}
                                                label={corePurpose.description} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Category *</InputLabel>
                                <Select defaultValue={0} label="Category *" value={categoryId} onChange={(e) => { setCategoryId(e.target.value) }}>
                                    <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                                    {categories ? categories.map(category => {
                                        return (<MenuItem key={category.id} value={category.id}> {category.name} </MenuItem>)
                                    }) : <MenuItem key={0} value={0}>{'No Category'} </MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Province *</InputLabel>
                                <Select defaultValue={0} label="Province *" value={provinceId} onChange={GetDistrictMunicipalityByProvinceId}>
                                    <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                                    {provinces ? provinces.map(province => {
                                        return (<MenuItem key={province.id} value={province.id}> {province.name} </MenuItem>)
                                    }) : <MenuItem key={0} value={0}>{'No Provinces'} </MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>District Municipality *</InputLabel>
                                <Select defaultValue={0} label="District Municipality *" value={districtMunicipalityId} onChange={GetLocalsByDistrictMunicipalityId} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {districtMunicipalities.map(districtMunicipality => {
                                        return (<MenuItem key={districtMunicipality.id} value={districtMunicipality.id}> {districtMunicipality.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Local District Municipality</InputLabel>
                                <Select defaultValue={0} label="Local District Municipality" value={localsByDistrictMunicipalityId} onChange={(e) => { setLocalsByDistrictMunicipalityId(e.target.value) }} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {localsByDistrictMunicipalities.map(localdistrictMunicipality => {
                                        return (<MenuItem key={localdistrictMunicipality.id} value={localdistrictMunicipality.id}> {localdistrictMunicipality.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} />
                        </Grid>


                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth multiline rows={2} label="Description" value={description} onChange={e => setDescription(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} >
                            <GoogleAddressAutoComplete value={mainOfficeaddress} onChange={setMainOfficeAddress} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth label="Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth label="Alternative Number" value={alternativeNumber} onChange={e => setAlternativeNumber(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth label="Email" value={email} onChange={e => setEmail(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth label="Alternative Email" value={alternativeEmail} onChange={e => setAlternativeEmail(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Button component="label" variant="contained" onChange={handleFileChange} startIcon={<CloudUploadIcon />} >
                                Upload Logo  (Logo of contributing organization will be shared publicly) <input type="file" onChange={handleFileChange} hidden multiple />
                            </Button>
                            {selectedFiles.length > 0 && (
                                <div>
                                    <p>Selected Files:</p>
                                    <ul>
                                        {selectedFiles.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <TextField fullWidth label="Website URL" value={website} onChange={e => setWebsite(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Dialog open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description" >
                        <DialogTitle id="scroll-dialog-title">Registration successful!</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                                We have sent an email with a confirmation link to your email address. Please allow 2-5 minutes for this message to arrive.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Ok</Button>
                        </DialogActions>
                    </Dialog>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button onClick={() => handleBack()}
                                color='primary'
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button disabled={isLoading || !enableSubmitButton()}
                                type="submit"
                                color='primary'
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
            <ForgotPasswordModal open={openForgetPasswordDialog} onClose={() => setOpenForgetPasswordDialog(false)} institutionSearchResult={institutionSearchResult} />
        </Card >
    )
}


export const ForgotPasswordModal = ({ open, onClose, institutionSearchResult }) => {
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle textAlign="center">Search Result</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    {institutionSearchResult}
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddInstitutionPage